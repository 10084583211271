.icons {
    color: white;
    height: 1.8rem;
    width: 1.8em;
    padding-right: 15px;
}

.icons:hover {
    color: #ddd;
}
