@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
body {
  background-color: rgb(92, 174, 252);
}

.greeting {
    font-family: 'Rubik', sans-serif;
    font-size: 150px;
    color: white;
    -webkit-align-content: center;
            align-content: center;
}

.introduction {
    font-family: 'Rubik', sans-serif;
    color: white;
}

.icons {
    color: white;
    height: 1.8rem;
    width: 1.8em;
    padding-right: 15px;
}

.icons:hover {
    color: #ddd;
}

.home {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

